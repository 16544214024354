/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
/* eslint-disable no-underscore-dangle */
import eventBus from '@sd-utility/eventbus';
import React from 'react';
import ReactDOM from 'react-dom';
import type {
  CIOProductsRec,
  CIOProductsResBodyEntry,
  CIORecProduct,
  CriteoBannerRec,
  CriteoProductsResBodyEntry,
  RecProduct,
} from 'server/services/recommendation';
import {
  GRID_UPDATE_EVENT,
  RenderType,
  UserAction,
} from 'shared/consts/recommendations';
import { dispatchRexInitiated, dispatchRexLoaded } from './rex-events';
import {
  EventBusProps,
  hideGridItems,
  shouldUpdateRecs,
} from './utils/gridUpdateEvent';

export enum Manufacturer {
  Apple = 'Apple',
}

if (!window.__ALL_RECS_QUERIES__) window.__ALL_RECS_QUERIES__ = {};

const renderInGrid = (
  { rec, provider }: CIOProductsResBodyEntry | CriteoProductsResBodyEntry,
  isSelectedFacet: boolean,
) => {
  const uvListing = window.universal_variable?.listing;
  if (uvListing?.pageType === 'promo') return;

  if (!rec) return;

  document
    .querySelectorAll<HTMLDivElement>(
      'div[data-testid="gallery-recs-container"].grid-item--recs-container',
    )
    .forEach(async (el, idx) => {
      const product = rec.productData[idx];
      if (!product) return;

      const { InGridProductCard } = await import('./components/InGrid');
      ReactDOM.render(
        <InGridProductCard provider={provider} product={product} />,
        el,
        () => {
          if (!isSelectedFacet) {
            el.classList.remove('grid-item--hidden');
          }
        },
      );
    });
};

const renderBanner = async (rec: CriteoBannerRec) => {
  const el = document.querySelector<HTMLDivElement>(
    'div[data-testid="recs-banner-container"].recs-banner',
  );
  if (!el || !rec) return;

  const { Banner } = await import('./components/Banner');
  ReactDOM.render(<Banner banner={rec} />, el, () => {
    el.classList.remove('recs-banner--hidden');
  });
};

const renderBannerInGrid = async (
  rec: CriteoBannerRec,
  isSelectedFacet: boolean,
) => {
  const uvListing = window.universal_variable?.listing;
  if (uvListing?.pageType === 'promo') return;

  const el = document.querySelector<HTMLDivElement>(
    'div[data-testid="gallery-banner-container"].grid-item--recs-banner',
  );
  if (!el || !rec) return;

  const { Banner } = await import('./components/Banner');

  ReactDOM.render(<Banner banner={rec} />, el, () => {
    if (!isSelectedFacet) {
      el.classList.remove('grid-item--hidden');
    }
  });
};

// eslint-disable-next-line import/prefer-default-export
export const renderAddToBasket = async (
  rec: CIOProductsRec | null,
  title = '',
) => {
  const containerId = 'recs-atb-container';
  const el = document.getElementById(containerId) as HTMLDivElement | null;
  if (!el || !(rec && rec.productData && rec.productData.length > 0)) return;

  const isCarousel =
    window.universal_variable?.product?.manufacturer === Manufacturer.Apple &&
    rec.productData.length > 1;

  const recProduct = isCarousel
    ? rec.productData.slice(0, 5)
    : rec.productData[0];

  const { AddToBasket } = await import('./components/AddToBasket');
  const { Carousel } = await import('./components/Carousel');

  const callback = () => el.classList.remove('recs-atb--hidden');

  ReactDOM.render(
    isCarousel ? (
      <Carousel
        lCardsPerPage={2}
        mCardsPerPage={2}
        xxsCardsPerPage={2}
        touchPeek
        products={recProduct as RecProduct[]}
        title={title}
        data-testid="recommendations-carousel-add-to-basket"
      />
    ) : (
      <AddToBasket product={recProduct as CIORecProduct} title={title} />
    ),
    el,
    callback,
  );
};

const getUUID = async () => {
  try {
    const module = (await import('client/utils/sessionStorage')) as {
      setSession: Function;
      getSession: Function;
    };
    const { setSession, getSession } = module;
    const existingId = getSession('criteo_visitorID');
    if (existingId) return existingId;

    const { v4: uuidv4 } = await import('uuid');
    const randomID = uuidv4();

    setSession('criteo_visitorID', randomID);

    return randomID;
  } catch (ex) {
    console.log('getUUIDFailed: ', ex);
    return '';
  }
};

const getCustomerID = () => window.cnstrcUserId || undefined;

const getTaxonomyValue = () => {
  const scriptTag = document.getElementById('taxonomyData');
  if (scriptTag && scriptTag.textContent) {
    try {
      const data = JSON.parse(scriptTag.textContent);
      return data.taxonomy;
    } catch (error) {
      return '';
    }
  }
  return '';
};

const dispatchInitiated = () => {
  window.__RECS_INITIATED__ = true;
  dispatchRexInitiated();
};

const getAllRoots = () =>
  document.querySelectorAll<HTMLDivElement & { _hydrated?: boolean }>(
    'div#recommendations-root',
  );

const removeAllRoots = () => getAllRoots().forEach((el) => el.remove());

export const isSpecificBrandPage = (href: string): boolean => {
  const brandRegex =
    /apple|macbook|iphone|ipad|airpods|imac|airtag|homepod|samsung|nike|adidas/i;
  return brandRegex.test(href);
};

export const shouldHideRecommendations = (): boolean =>
  isSpecificBrandPage(window.location.href);

// Temporary solution to hide sexual wellness products from Recs
export const filterProducts = (productData: CIORecProduct[]) => {
  const wellnessProducts = [
    'W12E5',
    'W12E6',
    'W12E7',
    'W12E8',
    'W12E9',
    'W12EA',
    'W12EB',
    'W12EC',
    'W12ED',
    'W12EE',
    'W12EF',
    'W12EG',
    'W12EH',
    'W12EI',
    'W12EJ',
    'W12EK',
    'W12E0',
    'W12E1',
    'W12E2',
    'W12E3',
    'W12E4',
  ];
  return productData.filter(
    (product) =>
      product.cnstrc && !wellnessProducts.includes(product.cnstrc.variationId),
  );
};

const runRecommendations = async (
  fromEventBus = false,
  isSelectedFacet = false,
) => {
  (async () => {
    const uv = window.universal_variable;
    if (!uv) {
      console.error(new Error('universal_variable_unavailable'));
      dispatchInitiated();
      return removeAllRoots();
    }

    const { default: Worker } = await import('./rex.worker');
    const worker = new Worker();

    const uvCopy = { ...uv };
    delete uvCopy.events;

    const postMessage = async () =>
      worker.postMessage({
        uv: uvCopy,
        qs: window.__ALL_RECS_QUERIES__,
        options: {
          ...window.__GLOBAL_RECS_STATE__,
          screenWidth: window.innerWidth,
          visitorId: await getUUID(),
        },
        customerID: getCustomerID(),
        taxonomy: getTaxonomyValue(),
      });

    worker.onmessage = async ({ data: { options, recs } }) => {
      if (!recs) {
        dispatchInitiated();
        worker.terminate();
        return removeAllRoots();
      }

      document
        .querySelectorAll<HTMLDivElement & { _hydrated?: boolean }>(
          'div#recommendations-root',
        )
        .forEach((el) => {
          const stateId = el.parentElement?.getAttribute('data-stateid');
          const debugText = 'previously hydrated, skipping hydration for';
          if (!fromEventBus) {
            // * prevents hydrating element multiple times
            if (el._hydrated) return console.debug(debugText, el);
          } else if (!stateId?.includes('recs3')) {
            if (el._hydrated) return console.debug(debugText, el);
          }

          if (!stateId) {
            console.error(new Error('state_id_not_found'));
            return el.remove();
          }

          // Remove Criteo Recs for specific brands
          if (stateId.includes('recs3') && shouldHideRecommendations()) {
            return el.remove();
          }

          const state = window.__ALL_RECS_QUERIES__[stateId];
          if (!state) {
            console.error(new Error('state_not_found'));
            return el.remove();
          }

          const render = async (
            entry: CIOProductsResBodyEntry | CriteoProductsResBodyEntry,
          ) => {
            // to avoid unnecessarily importing the app if the carousel is just going to disappear
            if (!entry.rec?.productData.length) return el.remove();

            const { default: App } = await import('./App');
            ReactDOM.render(
              <App
                brand={window.universal_variable?.brand}
                config={{
                  ...state.config,
                  options,
                  renderType: entry?.renderType,
                  rec: entry?.rec,
                  noResponse: !entry?.rec,
                }}
              />,
              el,
              () => {
                el._hydrated = true;
                dispatchRexLoaded({
                  provider: state.config.provider,
                  placement: state.config.placement,
                });
              },
            );
          };

          const res = recs[stateId];
          let inGridRecReturned = false;
          let carouselRecReturned = false;
          let inGridBannerReturned = false;

          if (!res) return el.remove();
          res.forEach((entry) => {
            if (
              entry.provider === 'recs2' &&
              entry.rec &&
              entry.rec.productData
            ) {
              entry.rec.productData = filterProducts(entry.rec.productData);
            }

            switch (entry.renderType) {
              case RenderType.Banner:
                return renderBanner(entry.rec);
              case RenderType.BannerInGrid:
                inGridBannerReturned = true;
                return renderBannerInGrid(entry.rec, isSelectedFacet);
              case RenderType.InGrid:
                inGridRecReturned = true;
                return renderInGrid(entry, isSelectedFacet);
              case RenderType.AddToBasket:
                return renderAddToBasket(entry.rec, entry.rec?.title);
              default:
                if (entry.provider === 'recs3') {
                  carouselRecReturned = true;
                }
                return render(entry as any);
            }
          });
          // PLP hide below grid skeleton if no recs are returned in carousel
          if (
            (inGridRecReturned || inGridBannerReturned) &&
            !carouselRecReturned
          ) {
            el.remove();
          }
        });

      dispatchInitiated();
      worker.terminate();
    };

    await postMessage();
  })();
};

if (!window.__RECS_INITIATING__) {
  window.__RECS_INITIATING__ = true;
  const isSelectedFacet =
    !!window.universal_variable?.listing?.filters?.numSelections;

  runRecommendations(false, isSelectedFacet);
}

// istanbul ignore next
if (module.hot) {
  module.hot.accept((error) => error && console.error(error));
}

function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const runRecommendationsDebounced = debounce(runRecommendations, 1000);

const subscription = eventBus.subscribe(
  GRID_UPDATE_EVENT,
  (e: EventBusProps) => {
    if (e?.pageType === 'search') return;

    if (shouldUpdateRecs(e)) {
      runRecommendationsDebounced(true, e?.isSelectedFacet);
    } else if (e?.eventTrigger === UserAction.FACET && e?.isSelectedFacet) {
      hideGridItems();
    }
  },
);

window.addEventListener('beforeunload', () => subscription.unsubscribe(), {
  once: true,
});
